import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

LogoFull.propTypes = {
  sx: PropTypes.object
};

export default function LogoFull({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    // <Box sx={{ width: 50, height: 10, ...sx }}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={50}
      height={50}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
    >
      <defs>
        <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
          <stop offset="0%" stopColor={PRIMARY_DARK} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
      </defs>
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M497.000000,250.666656 
    C489.577454,259.768097 482.181366,268.558868 474.715179,277.289612 
    C472.668182,279.683350 471.909454,282.515747 471.305542,285.474060 
    C468.710022,298.188232 465.933838,310.868347 463.564606,323.624268 
    C462.792816,327.779785 460.992676,328.773865 457.076691,328.045990 
    C442.210999,325.282898 427.278076,322.873016 412.441833,319.965942 
    C408.303131,319.154999 406.200226,320.601532 404.034424,324.858704 
    C422.721497,328.457245 441.286316,330.198822 459.184814,334.284119 
    C460.036499,336.658600 458.624420,337.845856 457.734711,339.165833 
    C448.422607,352.980682 438.994263,366.718506 429.819305,380.623596 
    C427.514832,384.116150 425.286346,385.428741 420.853485,384.579010 
    C411.232513,382.734894 401.452850,381.720062 391.741180,380.344604 
    C390.971893,380.235687 390.039062,380.295319 389.479980,378.301880 
    C397.430847,375.641846 405.455994,372.956940 414.077850,370.072418 
    C411.809448,367.904755 409.341034,367.921173 407.266266,367.564423 
    C394.649841,365.394897 381.992401,363.463074 369.346558,361.465332 
    C367.820343,361.224182 366.313751,361.310730 365.120117,362.448578 
    C358.755768,368.515625 352.409393,374.601593 346.435089,380.319061 
    C347.591400,383.694336 349.460175,384.933716 350.994781,386.409790 
    C368.036621,402.801117 385.086945,419.183838 402.190033,435.511200 
    C405.876007,439.029968 408.781799,442.942657 410.353058,447.858978 
    C412.444611,454.403412 410.991058,460.175812 406.315521,464.900146 
    C401.839203,469.423218 396.105011,470.258728 390.073883,468.684296 
    C385.094574,467.384430 381.144562,464.407684 377.613892,460.713898 
    C360.839569,443.164642 344.013733,425.664612 327.180542,408.171783 
    C326.049438,406.996368 325.158875,405.493317 322.402863,404.602325 
    C316.388489,410.798096 309.948547,417.268219 303.694427,423.913208 
    C294.138855,434.065979 283.346954,442.219818 269.358856,445.018311 
    C249.858047,448.919739 232.677795,443.911682 218.124222,430.503693 
    C210.412048,423.398590 203.080353,415.862579 195.849289,408.261902 
    C192.904755,405.166901 190.982544,405.367767 188.262573,408.406708 
    C183.377548,413.864563 178.112350,418.980255 173.141754,424.364197 
    C170.727432,426.979279 168.677750,427.704834 166.450882,424.272888 
    C165.916260,423.448944 165.111099,422.793243 164.397003,422.094269 
    C160.651993,418.428467 157.556046,413.359344 149.624939,417.169922 
    C152.532944,412.626312 154.080109,409.431091 156.698837,406.889740 
    C179.656647,384.610687 202.768021,362.485840 225.464310,339.943207 
    C235.492004,329.983368 244.667740,319.165771 254.230225,308.737549 
    C254.586487,309.058960 254.942749,309.380371 255.299011,309.701782 
    C251.980728,312.680695 248.592636,315.585846 245.355103,318.650085 
    C216.920624,345.562714 188.556488,372.549866 160.071167,399.408539 
    C151.363861,407.618561 142.180725,415.326172 133.527496,423.590515 
    C127.775795,429.083649 122.352104,434.965942 117.219124,441.043549 
    C114.300949,444.498718 111.627640,448.540344 114.140472,454.607483 
    C117.833672,447.080750 122.311584,441.083130 127.648994,435.695343 
    C132.447357,430.851654 132.873856,430.849243 136.900162,436.108765 
    C139.736862,439.814301 142.955811,443.222961 146.664581,446.088867 
    C149.108841,447.977570 149.102325,449.681519 146.931534,451.798981 
    C142.996460,455.637299 139.329498,459.759308 135.288437,463.477997 
    C130.104294,468.248566 123.891411,470.540497 116.869209,468.908936 
    C103.899353,465.895508 100.040764,449.916931 110.202667,439.298767 
    C120.789345,428.236755 132.192078,417.956299 143.224518,407.320190 
    C151.013458,399.811035 158.733780,392.229889 166.585678,384.787415 
    C168.819885,382.669769 168.698410,381.080566 166.560974,379.010223 
    C161.175964,373.794281 155.926331,368.438477 150.631851,363.129333 
    C149.525177,362.019592 148.320358,361.241425 146.650818,361.511536 
    C132.377991,363.820923 117.981583,365.395172 103.879288,368.711670 
    C103.435394,368.816040 103.054901,369.190125 101.975929,369.843628 
    C110.755554,372.808533 119.001915,375.593353 128.273148,378.724304 
    C123.127396,381.879089 118.356567,381.152954 114.005081,381.991760 
    C107.160271,383.311188 100.181023,383.921692 93.274597,384.940186 
    C91.226990,385.242157 89.685104,384.778900 88.520393,383.056671 
    C78.161118,367.738556 67.793015,352.426361 57.441010,337.103333 
    C56.976700,336.416046 56.600674,335.648895 57.226707,334.246674 
    C74.464828,330.361847 92.412720,328.444183 110.403633,325.047485 
    C109.363121,320.885620 107.599129,319.177887 103.594528,319.907379 
    C88.550499,322.647736 73.448723,325.076294 58.425175,327.920624 
    C54.041779,328.750519 52.116264,327.789001 51.269505,323.194611 
    C49.007339,310.920593 46.225254,298.743286 43.783470,286.500885 
    C42.954086,282.342590 41.571568,278.702271 38.636372,275.411926 
    C32.318592,268.329742 26.387390,260.901611 20.342026,253.578674 
    C19.414196,252.454773 18.141964,251.507736 17.908070,249.758224 
    C18.993742,248.055740 20.887789,248.046036 22.625559,247.684692 
    C30.925524,245.958862 39.218624,244.199570 47.524929,242.504959 
    C50.283081,241.942261 51.679451,240.760620 51.895046,237.558990 
    C52.895344,222.704605 58.592609,209.874939 69.224342,199.316940 
    C77.618797,190.980713 85.853683,182.480103 94.384102,174.286392 
    C97.559952,171.235901 97.956604,169.216675 94.609566,165.830048 
    C81.967598,153.038605 69.623077,139.952606 57.216354,126.930061 
    C55.658249,125.294601 53.854538,123.597755 54.627811,120.995056 
    C55.755661,119.891853 56.906811,120.290054 58.028198,120.578987 
    C60.812618,121.296410 62.309853,120.378899 63.119984,117.463684 
    C64.239037,113.436844 65.849434,113.120811 68.944862,116.090103 
    C79.401619,126.120766 89.800957,136.211258 100.238419,146.262085 
    C102.518204,148.457428 105.038826,150.427719 107.140518,152.777176 
    C110.377136,156.395386 112.861740,155.837845 116.139343,152.528915 
    C138.771500,129.680420 161.546066,106.972740 184.319489,84.264526 
    C195.173065,73.442032 206.015060,62.604057 217.053894,51.972019 
    C240.644897,29.250376 274.497620,29.025673 297.826477,51.991108 
    C331.071320,84.718025 363.844299,117.923813 396.905090,150.838409 
    C404.623932,158.523132 402.602570,158.445084 410.563293,150.912094 
    C418.429626,143.468460 426.208191,135.932175 434.052490,128.465164 
    C435.345856,127.233986 436.510437,125.692345 438.896973,125.697105 
    C440.945404,128.980438 443.369659,132.100967 446.715118,134.368683 
    C449.749268,136.425339 447.568756,138.073532 446.141144,139.576553 
    C437.764832,148.395279 429.474884,157.304123 420.849030,165.873734 
    C417.462524,169.238174 417.369293,171.510422 420.832825,174.830582 
    C429.008453,182.667770 436.722687,190.986710 444.908569,198.812653 
    C455.910187,209.330612 461.863892,222.153992 463.036621,237.178284 
    C463.312408,240.711731 464.653717,242.066559 467.957947,242.716446 
    C477.417419,244.576981 486.821198,246.720566 496.623444,248.876434 
    C497.000000,249.444443 497.000000,249.888901 497.000000,250.666656 
  M361.529297,141.973053 
    C336.062622,116.534119 310.647217,91.043533 285.110352,65.675262 
    C269.329681,49.998779 245.878540,49.932793 230.041275,65.484673 
    C213.048630,82.171173 196.174011,98.978294 179.297424,115.782501 
    C162.656250,132.352310 146.114777,149.022400 129.436386,165.554520 
    C126.385582,168.578568 125.798874,170.740295 129.350800,174.066513 
    C143.092865,186.935287 157.009338,199.671188 169.961945,213.310257 
    C178.144089,221.926025 186.278717,225.395477 198.687622,224.243439 
    C222.849716,222.000198 247.237930,222.223145 271.562347,222.595993 
    C289.051666,222.864075 306.527435,223.173248 323.963806,224.627975 
    C329.572235,225.095917 334.310547,224.559128 338.829956,219.974335 
    C351.805878,206.810638 365.449707,194.306976 378.756897,181.467102 
    C382.431671,177.921356 386.445648,174.663528 389.717224,170.073944 
    C380.391327,160.769608 371.216797,151.616302 361.529297,141.973053 
  M249.722183,344.224762 
    C235.814163,358.773499 221.930252,373.345428 207.969879,387.843719 
    C206.063660,389.823425 205.249313,391.367889 207.591141,393.640320 
    C215.709106,401.517731 223.462646,409.787140 231.814224,417.403717 
    C246.971619,431.227081 270.583130,430.387787 285.025635,415.588135 
    C292.227020,408.208588 299.495697,400.888092 306.936432,393.752075 
    C309.596375,391.201080 309.661865,389.580688 307.024628,386.894562 
    C295.952728,375.617065 285.191162,364.035248 274.143402,352.733490 
    C268.832947,347.300903 264.077515,341.283813 257.084839,335.715607 
    C254.728668,338.466736 252.477127,341.095703 249.722183,344.224762 
  M88.217659,235.617264 
    C109.084595,231.469269 130.409668,230.373032 151.258682,227.171997 
    C151.325668,224.637955 150.246078,223.759644 149.344177,222.815903 
    C138.074081,211.022995 126.706726,199.321320 115.567696,187.406097 
    C112.732079,184.372894 110.706383,184.358658 107.881821,187.241196 
    C99.264473,196.035461 90.387764,204.576813 81.824203,213.422058 
    C77.023346,218.380859 73.598816,224.265778 71.934105,231.078140 
    C70.400742,237.353027 71.322144,238.375320 77.505516,237.233826 
    C80.768341,236.631485 84.061218,236.192017 88.217659,235.617264 
  M381.803223,229.619858 
    C389.731750,230.497177 397.688171,231.178177 405.582153,232.298920 
    C416.769318,233.887177 427.913025,235.783829 439.069946,237.582077 
    C442.915497,238.201904 444.371704,236.820755 443.448914,232.906158 
    C442.251770,227.827682 440.671783,222.867020 437.393372,218.779755 
    C427.376373,206.291306 415.248505,195.867126 403.696106,184.029312 
    C389.979950,198.303986 376.651245,212.175400 363.322571,226.046829 
    C363.623627,226.477768 363.924652,226.908691 364.225708,227.339630 
    C369.784698,228.067703 375.343719,228.795792 381.803223,229.619858 
  M189.293533,317.665833 
    C199.485138,316.554749 209.682816,316.393951 219.922867,316.844543 
    C220.011688,319.423004 218.407120,320.304779 217.339417,321.484497 
    C208.631393,331.106323 199.881119,340.689880 191.175018,350.313416 
    C187.296509,354.600616 183.927704,359.675842 176.901688,358.549408 
    C176.140396,358.427368 175.303375,358.932465 174.841324,359.986725 
    C175.427307,362.493317 177.983185,363.542114 179.460037,365.455780 
    C181.811996,368.503387 183.989334,368.632233 186.728424,365.669342 
    C189.997314,362.133362 193.677582,358.979034 197.158508,355.637390 
    C209.763916,343.536560 222.302170,331.364777 235.004730,319.366913 
    C237.888275,316.643372 237.681366,314.728424 235.093506,311.960327 
    C232.608459,309.302185 230.022949,308.038269 226.360306,308.292847 
    C215.253479,309.064850 204.136917,309.729065 193.015533,310.252014 
    C176.239532,311.040802 159.558685,312.927551 142.875626,314.687408 
    C139.284332,315.066284 135.192200,314.295044 131.754074,316.874817 
    C134.753189,323.427155 140.182449,321.809662 145.145615,321.301544 
    C159.522049,319.829712 173.856842,317.885437 189.293533,317.665833 
  M326.263763,310.350525 
    C322.606018,310.254150 318.966156,309.935852 315.319336,309.658936 
    C306.033630,308.953827 296.710297,308.737091 287.400665,308.357971 
    C283.015106,308.179382 281.060944,311.512634 278.980591,314.481476 
    C277.725220,316.273010 279.112671,317.625305 280.323029,318.786377 
    C296.784332,334.577148 313.261108,350.351746 329.739075,366.125122 
    C330.879730,367.216949 332.257355,368.202606 333.681976,366.914337 
    C336.047211,364.775452 338.846893,362.909332 339.897522,359.520325 
    C336.640381,357.438995 332.642212,358.864380 329.767456,355.521545 
    C319.781982,343.910217 309.315399,332.713318 299.090851,321.305878 
    C298.078888,320.176819 296.280090,319.267548 297.108612,316.843842 
    C311.497375,316.022247 325.907471,317.318451 340.302429,318.318604 
    C351.732758,319.112793 363.121826,320.505707 374.526642,321.658417 
    C378.397522,322.049652 381.263733,320.745117 383.281982,316.373108 
    C364.456940,313.128387 345.753387,312.369415 326.263763,310.350525 
  M323.818542,373.665161 
    C325.981781,375.738190 328.178284,377.777771 330.302887,379.889709 
    C343.726013,393.233002 357.155243,406.570374 370.519257,419.972687 
    C372.289337,421.747864 374.620728,423.286896 374.928436,426.099792 
    C373.352417,427.129211 371.361908,425.584045 370.490753,427.181458 
    C368.272644,431.248749 365.990448,435.327118 364.320679,439.628784 
    C363.436157,441.907562 364.939117,444.349121 366.932526,445.683838 
    C368.985321,447.058289 370.269287,444.861908 371.497192,443.722290 
    C374.299683,441.121368 377.183258,438.522797 379.510773,435.518066 
    C382.029053,432.267059 384.046936,432.540619 386.844574,435.054047 
    C392.998871,440.583282 398.358276,446.648682 401.111145,455.259491 
    C403.855865,451.335663 403.003143,448.217621 401.264709,445.233124 
    C396.101562,436.369049 388.546570,429.485291 381.251007,422.577454 
    C374.519043,416.203308 367.473450,410.068604 360.667755,403.773682 
    C332.924988,378.113129 305.692841,351.900543 278.271179,325.892792 
    C276.720917,324.422424 275.191986,322.907623 272.869812,322.645813 
    C288.984833,340.266022 306.450775,356.421112 323.818542,373.665161 
  M449.265167,315.686798 
    C452.694458,316.938019 454.350830,315.916992 455.042999,312.130493 
    C456.807281,302.479218 459.134796,292.930634 460.885315,283.277191 
    C461.842438,277.998871 464.076904,273.654114 467.583649,269.645996 
    C471.355255,265.335205 475.381226,261.152588 478.353973,255.842041 
    C477.092834,255.318146 476.225708,254.780640 475.288849,254.594666 
    C452.149414,250.001419 428.935638,245.831329 405.545258,242.720856 
    C403.369049,242.431458 401.030396,242.510315 399.041321,244.428207 
    C423.385895,248.806793 447.831055,252.265442 471.767456,257.684814 
    C471.752106,260.588959 469.970154,261.560455 468.913940,262.989685 
    C464.729370,268.652100 459.238098,273.318726 458.225647,281.126129 
    C457.095276,289.842407 454.684723,298.384979 453.211517,307.067627 
    C452.416992,311.750214 450.291077,313.381866 445.779388,312.016479 
    C443.805115,311.419006 441.745667,310.620483 439.886139,312.904236 
    C442.523926,315.369354 445.660095,315.005920 449.265167,315.686798 
  M244.217926,232.425980 
    C205.105301,233.168869 166.114944,235.360764 127.480827,241.774765 
    C129.415054,242.670624 255.596527,234.485382 256.982880,233.463623 
    C252.667587,233.088715 248.908737,232.762146 244.217926,232.425980 
  M297.110962,282.610321 
    C298.324860,279.530762 297.185486,277.452423 294.318298,276.154602 
    C291.809875,275.019135 289.121155,275.168243 286.460846,275.132477 
    C283.598846,275.094025 282.167786,276.303955 282.301422,279.352875 
    C282.447021,282.675629 282.308411,286.010345 282.314819,289.339783 
    C282.328979,296.701782 282.871765,297.214661 290.355469,296.669403 
    C291.668335,296.573730 293.002563,296.320526 294.253967,295.914978 
    C298.424927,294.563263 299.454895,292.276489 297.730103,288.267914 
    C297.035522,286.653595 295.405670,285.255066 297.110962,282.610321 
  M167.984909,289.654358 
    C170.916641,290.837769 171.492737,293.912506 173.118820,296.132385 
    C174.248825,297.675049 175.250641,299.210175 177.480484,298.619781 
    C179.898193,297.979675 178.997375,296.007782 178.966660,294.482910 
    C178.896408,290.995361 178.910278,287.485168 178.487000,284.033600 
    C178.234909,281.977936 178.573929,279.280121 175.616165,278.333984 
    C172.772324,280.946167 175.736847,285.006378 173.062302,287.734436 
    C170.209930,285.782898 169.159363,282.650848 167.173660,280.287964 
    C165.999191,278.890442 164.751328,277.880402 162.919907,278.597870 
    C161.177734,279.280334 161.297806,280.867615 161.448303,282.419983 
    C161.816818,286.221497 162.003937,290.045837 162.524384,293.825104 
    C162.771866,295.622223 162.513718,297.972168 165.317734,298.642212 
    C168.304306,296.248077 165.587509,292.532227 167.984909,289.654358 
  M437.828125,300.248444 
    C439.260681,291.090576 434.962555,286.123138 425.583679,285.834473 
    C423.377625,285.766602 422.377747,286.670074 422.384369,288.607513 
    C422.402588,293.953247 421.310577,299.156342 420.599976,304.410004 
    C420.360260,306.182129 421.162140,307.124542 422.917511,307.609253 
    C429.533295,309.436127 434.738190,307.148193 437.828125,300.248444 
  M411.695007,285.845825 
    C408.879883,283.780792 405.652618,283.251862 402.256714,283.194763 
    C400.073181,283.158051 398.778412,283.919830 398.582062,286.244324 
    C398.176544,291.045319 397.648254,295.836578 397.297241,300.641113 
    C397.197418,302.007416 396.729523,303.762543 398.851379,304.288635 
    C401.939484,303.901367 400.174561,299.350525 403.377991,299.071564 
    C406.684540,300.005280 405.957214,305.746521 411.004059,305.422363 
    C409.266815,301.618134 408.815094,298.636292 412.266357,295.496948 
    C414.818542,293.175446 414.663330,289.427338 411.695007,285.845825 
  M262.346191,295.646301 
    C264.301636,296.829590 265.738464,296.792297 265.148041,294.001648 
    C263.871216,287.966705 261.810760,282.159180 259.397339,276.494843 
    C258.561401,274.532898 256.619934,274.267548 254.688522,274.519440 
    C253.126938,274.723145 252.329910,275.782837 252.316666,277.270691 
    C252.275558,281.891571 250.086380,285.934509 248.949493,290.258392 
    C248.470459,292.080322 247.486740,294.033630 249.669693,295.510651 
    C254.836960,291.645416 258.340546,291.568817 262.346191,295.646301 
  M77.370102,305.820831 
    C77.818016,307.026825 78.012848,308.503876 79.919312,308.209442 
    C81.470551,306.920105 80.959091,305.156311 80.893753,303.578552 
    C80.779373,300.815979 82.087608,299.843445 84.721382,299.542358 
    C87.667526,299.205536 89.053391,300.119568 89.360069,303.119751 
    C89.531464,304.796448 89.526070,307.544403 91.940781,307.145264 
    C94.821800,306.669128 93.440720,304.015259 93.208298,302.196198 
    C92.809311,299.073547 92.243439,295.972595 91.813248,292.853455 
    C91.506218,290.627289 91.863419,288.173462 89.536240,286.675720 
    C86.687363,287.600281 87.556129,289.723755 87.721741,291.641418 
    C88.042389,295.354340 85.375183,297.559540 81.923424,296.514404 
    C79.350914,295.735535 79.955757,293.387909 79.379440,291.592438 
    C78.885857,290.054657 78.487328,287.981506 76.238594,288.557526 
    C74.340034,289.043884 74.750587,290.791138 75.027443,292.312500 
    C75.798714,296.550781 76.508186,300.800293 77.370102,305.820831 
  M132.499588,286.184021 
    C132.281738,285.211029 132.098434,284.228668 131.837524,283.267334 
    C131.486755,281.974945 130.656738,281.243042 129.268127,281.513397 
    C127.596230,281.838898 127.227211,283.101044 127.443542,284.544434 
    C128.010056,288.324432 128.476212,292.132751 129.312698,295.855560 
    C130.275909,300.142395 134.061172,302.457642 138.365707,301.754242 
    C142.806091,301.028625 145.767227,297.315338 145.390030,292.948608 
    C145.161362,290.301392 144.982910,287.648773 144.693192,285.008301 
    C144.485107,283.111908 144.804504,280.353058 141.965332,280.629059 
    C139.395355,280.878906 140.254608,283.489807 140.386124,285.149292 
    C140.635025,288.290009 141.479965,291.387878 141.108398,294.573425 
    C140.874023,296.582733 139.942703,298.034760 137.721863,298.231689 
    C135.582458,298.421448 134.285278,297.392426 133.590286,295.410797 
    C132.633270,292.682037 132.816422,289.826874 132.499588,286.184021 
  M224.103973,291.356049 
    C224.431992,292.873230 223.879623,295.254852 226.272873,295.141388 
    C228.723846,295.025177 227.953293,292.645844 227.975769,291.160187 
    C228.031097,287.503204 227.912308,283.839996 227.743378,280.184845 
    C227.671082,278.620483 227.879486,276.671387 225.317535,276.633942 
    C223.320526,278.079163 224.295181,280.308990 223.870483,282.148865 
    C223.371552,284.310455 221.870224,285.077393 219.864059,285.180817 
    C218.089706,285.272308 216.439682,284.960602 215.939606,282.943726 
    C215.663971,281.832001 215.716232,280.636932 215.640778,279.478119 
    C215.520859,277.636139 214.981216,276.010620 212.740662,276.360687 
    C210.719208,276.676575 210.995972,278.332520 211.077881,279.901611 
    C211.285797,283.885559 211.241226,287.884521 211.518082,291.862122 
    C211.626862,293.424927 211.805618,295.574829 214.052551,295.501709 
    C216.160553,295.433136 215.834625,293.418793 215.872162,292.008087 
    C215.931702,289.769928 216.539124,287.988159 219.053009,287.723907 
    C221.343719,287.483185 223.456039,287.796265 224.103973,291.356049 
  M387.651123,302.894958 
    C388.008545,302.597748 388.687805,302.287170 388.676117,302.005249 
    C388.406158,295.491211 386.415192,289.310089 384.849518,283.053131 
    C384.410919,281.300476 382.737762,281.132935 381.176941,281.172211 
    C379.684845,281.209686 378.536560,281.905640 378.247559,283.386108 
    C377.534271,287.040100 375.304779,290.074677 374.232086,293.559357 
    C373.559692,295.743561 371.566589,297.813171 373.095520,300.426422 
    C378.723480,298.323608 382.376251,296.524994 387.651123,302.894958 
  M194.864029,276.529419 
    C187.660553,277.909210 184.412872,282.315186 185.552353,289.162140 
    C186.795380,296.631287 190.617081,298.093384 203.203171,295.914978 
    C204.830261,288.264191 203.471939,285.853516 197.898865,287.185486 
    C198.912521,289.573029 200.414536,292.302795 197.006287,293.890167 
    C195.057571,294.797821 193.096298,294.388702 191.606415,292.610565 
    C188.381653,288.761841 189.559250,282.310455 193.975815,279.732361 
    C195.128098,279.059723 197.346649,278.940430 194.864029,276.529419 
  M187.867340,326.400848 
    C183.770264,327.205261 179.486938,327.221924 174.586533,329.283722 
    C180.376083,330.732849 185.424728,328.648254 191.450500,330.975739 
    C183.953964,337.370270 180.442291,346.919159 169.159897,348.327301 
    C174.123962,350.640472 177.801773,350.305603 181.025101,346.546936 
    C183.187653,344.025238 185.747620,341.846924 187.948639,339.355011 
    C191.307327,335.552399 195.547638,332.478973 198.140610,327.748413 
    C195.018784,325.365845 191.765854,326.661957 187.867340,326.400848 
  M326.423309,330.504730 
    C328.437225,328.757111 331.709045,330.661194 334.032166,327.747681 
    C328.547729,326.214172 323.666321,325.404236 318.398193,327.537262 
    C324.327209,337.170380 337.546509,349.040924 341.812042,348.475739 
    C341.199097,347.903351 340.726685,347.529358 340.333801,347.085052 
    C335.636536,341.772522 329.761963,337.510315 326.423309,330.504730 
  M411.615967,335.565155 
    C401.081757,333.794708 390.651947,330.950531 379.768585,332.476227 
    C391.193451,336.144348 402.951843,337.462830 415.284058,338.928528 
    C414.512543,336.561371 413.308685,336.315063 411.615967,335.565155 
  M117.428795,292.050385 
    C115.950531,287.912476 117.323067,284.626740 120.649246,281.614441 
    C118.597519,283.158356 116.630280,282.051483 114.590363,281.742493 
    C113.451202,286.586182 115.358772,290.937042 115.300156,295.385284 
    C115.251373,299.087585 115.183205,302.818390 110.376976,303.130432 
    C105.705292,303.433777 104.264107,300.198334 103.599365,296.140503 
    C102.948318,292.166290 103.325287,287.936371 100.712273,284.335846 
    C100.941833,290.135162 100.440910,296.063995 103.201309,301.454437 
    C104.383156,303.762268 106.658974,304.892731 109.455719,304.865570 
    C115.735298,304.804626 118.017494,301.492645 117.428795,292.050385 
  M347.782593,297.010132 
    C344.634583,296.410248 343.376160,294.597504 343.776031,291.358032 
    C344.201263,287.913177 344.374695,284.433929 344.559662,280.964417 
    C344.634247,279.565979 344.179169,278.252350 342.605133,277.902222 
    C341.167694,277.582458 340.331543,278.477570 340.248291,279.783386 
    C339.901276,285.226471 339.499725,290.674591 339.445648,296.124146 
    C339.418396,298.870575 341.732727,299.715942 344.017487,299.530853 
    C345.603119,299.402405 348.497101,301.036438 347.782593,297.010132 
  M318.353119,290.582672 
    C318.714478,292.899841 317.086334,296.203522 320.006592,297.248901 
    C322.721649,298.220825 326.002869,299.750885 330.951324,296.026611 
    C320.860992,296.613281 322.981781,290.660583 322.905548,285.914795 
    C322.873688,283.931854 323.020538,281.945251 322.966003,279.963623 
    C322.930084,278.657654 322.901855,277.063690 321.091644,277.002625 
    C319.277832,276.941406 319.174072,278.450745 319.077301,279.811829 
    C318.843262,283.104065 318.603790,286.395935 318.353119,290.582672 
  M414.350616,358.450348 
    C413.846558,355.587494 411.210541,355.854706 409.396576,355.481476 
    C403.094086,354.184753 396.726685,353.189911 390.365509,352.198883 
    C387.912842,351.816772 385.370483,350.452362 381.428223,353.081696 
    C392.861542,356.389954 403.283783,357.857605 414.350616,358.450348 
  M114.209839,333.880554 
    C110.828293,334.703888 107.040047,334.234344 104.285362,337.296997 
    C114.673447,337.894135 124.625153,335.642395 134.537872,332.914886 
    C127.877533,330.645844 121.501480,332.977783 114.209839,333.880554 
  M125.130180,355.583893 
    C128.946060,354.485504 133.333191,355.066498 137.594330,351.165436 
    C125.982933,351.510345 115.925896,353.286774 106.144547,356.785614 
    C112.341713,357.958008 118.287079,356.526031 125.130180,355.583893 
  M310.888641,286.539459 
    C310.993530,284.053192 311.136353,281.567688 311.188812,279.080322 
    C311.219238,277.636505 310.785583,276.340973 309.053802,276.305481 
    C307.343811,276.270447 306.601837,277.530060 306.833374,278.957153 
    C307.539185,283.307709 305.916870,287.537567 306.349335,291.853394 
    C306.524811,293.604553 306.154083,295.603088 308.613129,296.536530 
    C311.676239,294.092377 310.562103,290.573242 310.888641,286.539459 
  M235.952393,290.313477 
    C236.354584,292.214905 235.290955,294.795197 238.987488,295.729004 
    C240.781342,289.406097 240.096329,283.106415 239.629013,276.850555 
    C239.528030,275.498840 238.199081,275.107544 236.932892,275.389313 
    C235.553299,275.696320 235.185944,276.804108 235.252670,278.047455 
    C235.456146,281.839722 235.706635,285.629486 235.952393,290.313477 
  M364.823883,288.417725 
    C364.873016,286.431702 365.000641,284.443359 364.941467,282.460571 
    C364.906708,281.295105 364.607239,280.020111 363.103271,279.936218 
    C361.548309,279.849426 361.231689,281.067169 361.149200,282.276947 
    C360.834259,286.895203 360.509796,291.514069 360.306580,296.137878 
    C360.244751,297.544159 360.038605,299.276245 362.601990,299.536255 
    C364.732819,296.504883 364.505554,292.852722 364.823883,288.417725 
  M67.690323,127.333382 
    C69.477669,126.350540 71.228455,125.292450 70.434013,122.808167 
    C70.019287,121.511314 69.098389,120.085457 67.635910,120.726761 
    C65.092453,121.842087 63.015316,123.632568 62.705502,126.533150 
    C64.090691,128.172699 65.531578,127.975349 67.690323,127.333382 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M294.272583,499.972382 
    C294.784760,502.475983 295.379669,504.556946 295.655548,506.679413 
    C296.215210,510.984955 294.036865,513.739868 290.082520,514.847595 
    C285.940002,516.007935 282.848755,514.272705 281.462158,510.226898 
    C280.394379,507.111389 279.644409,503.864716 279.019073,500.625031 
    C278.080963,495.764984 280.335480,492.228729 284.772430,491.186890 
    C288.832489,490.233551 291.931580,492.382172 293.454895,497.205109 
    C293.704010,497.993835 293.958832,498.780792 294.272583,499.972382 
  M288.141968,497.726135 
    C285.789185,495.788422 284.276428,496.559113 284.302399,499.373260 
    C284.325073,501.825348 284.981201,504.305450 285.614990,506.705109 
    C285.981201,508.091705 286.607239,509.831421 288.636902,509.231049 
    C290.353882,508.723114 290.795532,507.258301 290.393463,505.604187 
    C289.803558,503.177307 289.139038,500.768524 288.141968,497.726135 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M206.840759,496.754242 
    C207.924942,501.514069 206.534775,504.826233 202.070663,505.996033 
    C198.305161,506.982758 195.634201,505.124298 194.307907,501.517426 
    C193.449234,499.182159 193.291519,496.356232 195.769180,495.402771 
    C198.732971,494.262177 199.064560,497.063873 199.424011,499.251007 
    C199.481918,499.603333 200.044098,499.872803 200.594025,500.387115 
    C202.441299,498.051178 200.547470,496.603149 199.607773,495.148651 
    C197.844498,492.419617 197.332733,489.705444 198.903000,486.723938 
    C200.798340,483.125183 204.803299,481.812866 208.150360,483.752014 
    C211.185654,485.510498 212.403564,490.403015 210.586380,493.434296 
    C210.051880,494.325928 209.493256,495.150970 208.290863,494.877533 
    C207.088989,494.604218 206.171036,493.960785 205.923782,492.643219 
    C205.692139,491.408905 206.741959,490.030853 205.542007,488.883026 
    C203.082794,490.625885 203.082794,490.625885 206.840759,496.754242 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M178.537811,494.782410 
    C177.231293,493.971741 176.302841,493.258850 175.285660,492.710541 
    C171.481110,490.659698 171.085434,488.182678 173.758484,484.735901 
    C176.495621,481.206635 178.810959,477.352966 181.392517,473.698914 
    C182.407898,472.261749 183.464767,470.154877 185.654358,471.291748 
    C188.380814,472.707336 191.790543,473.837036 192.721054,477.050995 
    C193.268600,478.942230 191.659683,480.415314 189.237000,479.699005 
    C187.768173,479.264740 186.477005,477.554474 184.737396,478.994904 
    C184.104599,480.432037 185.037872,481.198669 185.928452,481.955139 
    C187.229156,483.059967 188.598724,484.126160 187.329910,486.086182 
    C186.092102,487.998383 184.607788,487.383942 183.011841,486.577118 
    C181.885590,486.007751 180.540573,484.961395 179.464706,486.484619 
    C178.162460,488.328400 179.897385,489.093750 181.029144,489.974335 
    C182.120529,490.823517 183.210663,491.730469 182.512192,493.261566 
    C181.827774,494.761871 180.624786,495.546204 178.537811,494.782410 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M256.178772,516.878418 
    C255.052429,513.067139 257.532532,510.914978 259.036530,508.406433 
    C260.502502,505.961212 262.768860,503.888031 262.886322,500.837006 
    C261.361023,500.083923 260.674225,500.618286 260.215424,501.687012 
    C259.429169,503.518677 258.053253,504.405609 256.264069,503.333984 
    C254.448868,502.246826 255.055298,500.443695 255.630661,498.847321 
    C256.960480,495.157654 260.654938,493.265442 263.953156,494.547241 
    C267.605194,495.966583 269.464935,500.186157 267.795593,504.108032 
    C266.704041,506.672516 265.027344,508.987915 263.699432,511.266144 
    C264.141968,512.909058 265.353943,512.461853 266.241852,512.571106 
    C267.943726,512.780579 269.111877,513.443237 269.089600,515.344666 
    C269.067017,517.275757 267.730713,518.027954 266.130798,518.027222 
    C262.872070,518.025696 259.504181,519.098755 256.178772,516.878418 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M314.390900,498.784851 
    C316.233551,500.940308 320.302856,496.829773 320.831573,501.260254 
    C321.298584,505.173279 317.305573,505.060577 315.007050,506.214874 
    C313.374634,507.034698 311.484802,508.810303 309.507690,507.184906 
    C307.401154,505.453125 307.989838,503.073181 308.461243,500.708771 
    C309.195038,497.028290 310.837524,493.333405 309.619171,489.531036 
    C307.779968,489.531067 307.436401,490.293579 307.383270,491.157928 
    C307.269806,493.003784 306.731384,494.746094 304.583191,494.441498 
    C302.312012,494.119476 301.958862,491.963379 302.147858,490.132965 
    C302.522522,486.504791 304.234924,483.584290 308.237549,483.416992 
    C312.274658,483.248291 314.761993,485.459442 315.328003,489.587921 
    C315.745270,492.631470 314.725372,495.512573 314.390900,498.784851 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M336.250885,481.395996 
    C341.089874,482.309967 341.541962,485.564728 341.186798,489.290619 
    C341.125977,489.928375 341.041748,490.643890 340.711792,491.159180 
    C340.041718,492.205627 338.712585,493.094788 337.805786,492.227844 
    C334.765350,489.320953 328.653076,497.528076 327.303802,489.237701 
    C326.614136,485.000214 325.896667,480.766571 325.275757,476.518890 
    C325.016968,474.748779 325.602753,473.236053 327.288055,472.355011 
    C329.387939,471.257172 330.320801,472.912537 331.266357,474.205231 
    C332.922852,476.469879 334.451599,478.827942 336.250885,481.395996 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M220.526062,492.185822 
    C220.821335,489.489624 222.433975,489.360931 224.217529,489.736725 
    C226.004913,490.113342 227.806183,490.517761 229.515366,491.144531 
    C231.092529,491.722839 233.514679,491.388458 233.597473,493.993225 
    C233.671860,496.333710 232.018219,496.930328 230.026047,497.303741 
    C228.194626,497.647034 228.176163,499.503113 227.783615,500.878357 
    C226.916885,503.914734 226.212524,506.997101 225.414185,510.053497 
    C224.915527,511.962616 224.679077,514.417480 221.765549,513.533691 
    C219.314423,512.790222 219.951706,510.794098 220.469254,508.956604 
    C221.055008,506.876892 221.355667,504.702393 222.091904,502.682678 
    C223.455109,498.943024 223.976822,495.450287 220.526062,492.185822 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M240.960312,511.982147 
    C244.049973,512.449402 244.468109,514.088806 243.303696,516.263794 
    C242.505814,517.754150 241.029129,517.755554 239.959305,516.771912 
    C238.101562,515.063904 238.323853,513.346863 240.960312,511.982147 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M289.566376,81.355011 
    C309.157440,92.193588 320.871796,108.339020 324.800232,129.999512 
    C331.919464,169.253098 303.170074,206.500153 263.686432,209.173340 
    C227.731750,211.607590 199.226868,189.989929 191.378311,155.162399 
    C182.664413,116.494881 212.260864,76.712807 251.654022,73.754242 
    C264.985779,72.752975 277.459900,74.927292 289.566376,81.355011 
  M254.206451,113.569099 
    C239.532318,116.919975 230.980789,125.779434 229.436661,139.230820 
    C228.196213,150.036621 234.858078,161.397171 245.456085,166.840240 
    C262.755737,175.725250 285.793304,164.134109 286.478027,141.610611 
    C286.982483,125.017784 272.404114,112.489166 254.206451,113.569099 
  M283.723328,96.762108 
    C291.794556,102.247292 298.429291,109.868210 307.202057,113.908539 
    C308.834259,112.303131 308.785980,111.294495 308.617249,110.305481 
    C306.922913,100.373985 287.592377,84.982727 277.335114,85.398621 
    C274.359711,85.519272 273.639984,87.161018 275.490417,89.231232 
    C277.800354,91.815498 280.589111,93.971794 283.723328,96.762108 
  M259.125488,81.529716 
    C258.492828,81.898018 257.997589,82.692390 258.611053,83.050201 
    C261.651489,84.823570 264.748169,85.280090 268.300232,83.124001 
    C265.313416,80.644905 262.513458,81.126907 259.125488,81.529716 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M148.126953,163.120789 
    C155.096939,157.236954 161.806015,151.594635 168.515076,145.952301 
    C168.885513,146.316147 169.255936,146.679993 169.626358,147.043839 
    C164.407394,155.047836 155.842346,160.301392 150.524872,168.781219 
    C157.342865,175.891998 165.269562,181.719925 172.486588,188.320709 
    C175.188477,190.791885 178.458496,192.740906 180.786194,195.503693 
    C187.818970,203.851028 197.191818,205.769745 207.393951,206.773331 
    C223.096558,208.318008 238.725632,210.612610 254.383667,212.606598 
    C261.954193,213.570679 269.241180,211.297043 276.645416,210.534882 
    C291.853119,208.969437 306.989258,206.714325 322.164429,204.819702 
    C324.067535,204.582108 325.694824,204.011490 327.131378,202.769485 
    C339.728119,191.878738 352.335602,181.000397 364.921143,170.096710 
    C365.394989,169.686188 365.677155,169.054443 366.291748,168.179840 
    C359.491150,161.008789 352.735840,153.885483 345.518494,146.274979 
    C348.613220,145.763840 349.650970,147.290482 350.838318,148.287140 
    C359.000641,155.138733 367.010376,162.175552 375.272583,168.903000 
    C378.431915,171.475449 377.935181,173.203079 375.194397,175.549728 
    C360.776367,187.894302 346.404083,200.292877 332.090027,212.757828 
    C329.854584,214.704483 327.369049,214.968674 324.665314,214.971893 
    C306.010132,214.994156 287.354950,215.061295 268.699768,215.059891 
    C243.753403,215.057999 218.806366,214.902786 193.861237,215.064697 
    C188.827026,215.097366 185.015289,213.504456 181.313553,210.216278 
    C168.490860,198.826065 155.464813,187.664581 142.503189,176.431030 
    C137.684204,172.254547 137.666946,171.981964 142.517502,167.870728 
    C144.296265,166.363098 146.082947,164.864822 148.126953,163.120789 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M247.333633,376.772766 
    C250.704803,376.492737 250.645767,373.893768 251.384033,371.992554 
    C253.208344,367.294525 253.924194,362.134277 256.881653,357.659241 
    C260.426880,359.282288 260.332764,362.441040 261.192993,364.934418 
    C266.247925,379.586487 262.600922,376.439240 277.172791,376.866821 
    C282.900665,377.034882 282.904541,376.903229 285.889191,378.449768 
    C285.065155,382.146698 281.566650,383.221191 279.043915,385.084595 
    C270.195312,391.620483 270.187622,391.529602 273.296356,402.074219 
    C274.209991,405.173157 276.020569,408.151917 275.240875,411.767914 
    C271.705841,412.582916 270.073090,409.741455 267.893494,408.284943 
    C254.781097,399.522675 260.267273,399.141663 247.165573,408.220215 
    C244.976593,409.737030 242.794373,413.623199 240.190170,411.774231 
    C237.310852,409.730103 240.333176,406.265289 241.117401,403.631012 
    C244.736969,391.472290 244.828461,391.548492 234.367508,384.040070 
    C232.311340,382.564240 229.535110,381.623566 228.851364,377.692505 
    C234.861252,376.058411 240.966827,377.275452 247.333633,376.772766 
  M252.076538,390.398956 
    C251.207596,391.981781 250.135880,393.532837 251.769867,396.415802 
    C255.919006,392.481598 260.158508,393.819916 264.288666,397.623840 
    C264.100891,389.748291 260.127319,387.451904 252.076538,390.398956 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M287.031006,291.564484 
    C286.881866,289.292297 286.781128,287.301941 289.300323,286.989319 
    C291.250092,286.747375 292.957214,287.407532 293.782898,289.358154 
    C294.384460,290.779236 293.794678,292.067200 292.617279,292.839294 
    C290.607758,294.157043 288.596222,294.464386 287.031006,291.564484 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M291.711945,282.858124 
    C289.076324,284.251587 287.664673,283.479675 287.426514,280.877350 
    C287.285583,279.337128 288.169434,278.268738 289.754028,278.469177 
    C292.270233,278.787476 293.056915,280.265045 291.711945,282.858124 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M433.321411,299.987762 
    C432.664215,302.186188 431.679688,303.686462 429.848755,304.383789 
    C426.673370,305.593140 424.989807,304.644562 425.462250,301.055328 
    C425.805847,298.445221 426.271545,295.850830 426.702881,293.252777 
    C426.945374,291.792145 427.046875,290.153442 429.019806,289.971924 
    C430.668335,289.820282 431.847046,290.780792 432.732758,292.027313 
    C434.416290,294.396606 434.213837,296.985107 433.321411,299.987762 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M405.025574,286.914429 
    C408.568359,287.170776 409.745636,289.038208 409.277924,292.002716 
    C408.901642,294.387817 407.251617,295.326172 404.969757,295.174683 
    C402.315643,294.998444 402.326874,293.136932 402.632660,291.195312 
    C402.883636,289.601654 402.695435,287.773132 405.025574,286.914429 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M258.683075,286.547333 
    C258.617401,289.870361 256.217163,289.821594 255.008530,288.685974 
    C252.754135,286.567749 255.004837,284.648102 256.041931,282.751373 
    C258.150574,283.208984 258.162140,284.845215 258.683075,286.547333 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M378.907196,292.049652 
    C379.628540,290.598450 379.920410,289.185669 381.623718,288.932404 
    C383.642151,290.381592 383.534637,292.441132 383.191528,294.911987 
    C381.077698,295.030548 378.402618,296.698730 378.907196,292.049652 
  z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
  M252.755997,119.544571 
    C258.940857,114.823708 267.186493,120.024292 266.465149,127.636200 
    C266.001984,132.523529 266.706177,137.089310 269.170654,141.878586 
    C271.960266,147.299698 270.867889,153.931961 269.693787,159.953934 
    C269.093445,163.032852 266.371338,164.984543 263.264709,165.608459 
    C259.504181,166.363693 255.660095,166.386139 251.896790,165.416595 
    C249.727798,164.857788 247.997147,163.621002 246.801208,161.675339 
    C243.942719,157.024963 244.286804,143.968170 247.733322,139.647324 
    C249.265503,137.726471 249.408295,136.031830 249.167999,133.734558 
    C248.650497,128.787399 248.119644,123.673080 252.755997,119.544571 
  M251.364761,148.961761 
    C251.669998,151.978668 250.133530,155.610077 253.187958,157.757812 
    C255.891373,159.658722 259.463654,159.612778 262.101715,157.872360 
    C265.669342,155.518646 264.854736,151.413605 264.337555,147.856308 
    C263.850677,144.507462 261.307281,143.454681 258.171173,143.354019 
    C254.657364,143.241211 251.901978,144.056870 251.364761,148.961761 
  M255.463089,132.758560 
    C256.403961,134.897964 258.133606,135.579712 259.663727,133.881256 
    C261.447845,131.900848 260.906464,129.240067 260.096680,126.883759 
    C259.692200,125.706802 258.542816,125.102455 257.282135,125.514328 
    C254.708115,126.355270 255.436142,128.633118 255.291199,130.503204 
    C255.253464,130.990372 255.324707,131.485992 255.463089,132.758560 
  z"
      />
    </svg>
    // </Box>
  );
}
